import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="プライバシーポリシー｜安い、安心、高品質のミスプラチナ"
        description="高品質でも安い結婚指輪はMissPlatinum。ストレートラインの結婚指輪はプラチナで29,800円！ゴールドは19,800円！と安い価格帯で通販、ご来店でもご利用頂けます。"
        page="privacy-p"
    />
)

const PrivacyProlicyPage: React.FC = () => {
    return (
        <Layout breadcrumbs={[{ name: 'プライバシーポリシー' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12} component="main">
                    <H1>
                        <Txt>プライバシーポリシー</Txt>
                    </H1>
                    <PPWrapper>
                        <P>
                            「Miss
                            Platinum」（以下、「当社」といいます）ではお客様に当社をご利用していただく際に、
                            お客様の氏名、住所、電話番号、メールアドレスなどお取引やご連絡に必要な情報を開示していただきます。
                            当社は、個人情報保護法を順守し、このプライバシーポリシーにのっとって個人情報を取り扱います。
                        </P>
                        <H2>
                            1.個人情報の利用目的
                            当社は、当社が取得した個人情報を以下の目的で利用します。
                        </H2>
                        <P>
                            1) お客様への商品の発送および代金の請求のため
                            <br />
                            2) 当社のサービス改善を行うため
                            <br />
                            3)
                            お客様からのご要望やお問い合わせに対する回答をするため
                        </P>
                        <H2>
                            2.個人情報の管理について
                            当社は以下の体制で個人情報を管理します。
                        </H2>
                        <P>
                            1)
                            個人情報保護法やガイドラインに従って必要な社内体制を整備し、従業員から個人情報の取り扱いを適正に行う旨の誓約書を取得します。
                            <br />
                            2)
                            個人情報の利用を業務上必要な社員だけに制限し、個人情報が含まれる媒体などの保管・管理などに関する規則を作り、個人情報保護のための措置を講じます。
                            <br />
                            3)
                            システムに保存されている個人情報については、業務上必要な社員だけが利用できるようアカウントとパスワードを用意し、アクセス権限管理を実施します。なお、アカウントとパスワードは漏えい、滅失のないよう厳重に管理します。
                            <br />
                            4)
                            インターネットによる個人情報にかかわるデータ伝送時のセキュリティーのため、必要なウェブページに業界標準の暗号化通信であるSSLを使用します。
                            <br />
                            5)
                            サービスに支障が生じないことを前提として、個人情報の受領時から一定期間経過後、個人情報は随時削除していきます。
                            <br />
                        </P>
                        <H2>3.第三者提供について</H2>
                        <P>
                            1)
                            前項の場合を除き、当社は、お客様の同意がない限り、個人情報を第三者に開示することはありません。ただし、以下の事例に該当する場合はその限りではありません。
                        </P>
                        <UL>
                            <li>
                                法令に基づき裁判所や警察等の公的機関から要請があった場合
                            </li>
                            <li>法令に特別の規定がある場合</li>
                            <li>
                                お客様や第三者の生命・身体・財産を損なうおそれがあり、本人の同意を得ることができない場合
                            </li>
                            <li>
                                法令や当社の利用規約・注意事項に反する行動から、当社の権利、財産またはサービスを保護または防御する必要があり、本人の同意を得ることができない場合
                            </li>
                        </UL>
                        <H2>4.個人情報の開示について</H2>
                        <P>
                            お客様から個人情報の開示要求があった場合は、本人であることが確認できた場合に限り開示します。
                            <br />
                            注：本人確認の方法
                            本人であることが証明できるもの（免許証、保険証など）の写しを、当社宛てに郵送してください。内容を確認させていただき、本人であることが明確になり次第、開示させていただきます。
                        </P>
                        <P>
                            情報管理責任者：株式会社ミスプラチナ
                            プライバシーポリシー詳細 「【Miss
                            Platinum】」（以下、「当社」といいます）ではお客様に当社をご利用して頂く際に、
                            お客様の氏名、住所、電話番号、e-mailアドレスなどお取引やご連絡に必要な情報（以下「個人情報」といいます）を開示していただきます。
                            <br />
                            当社は、個人情報保護法の趣旨のもと、このプライバシーポリシーに則って個人情報を取り扱います。
                            このプライバシーポリシーは、当社が開示いただいた個人情報の取扱いに関するお客様と当社との間のお約束です。
                        </P>
                        <H2>
                            1.個人情報の利用目的について
                            当社は個人情報を以下の目的で利用させていただきます。
                        </H2>
                        <P>
                            1)
                            お客様への商品の発送および代金の請求のためにお客様の氏名、住所、電話番号などの連絡先情報を利用します。また、代金の請求に関連してご指定いただいたクレジットカード番号、銀行口座などのお支払情報を利用する場合があります。
                            <br />
                            2)
                            ご注文の内容や配送方法などを連絡したり確認するために、お客様の氏名、住所、e-mailアドレスなどの連絡先情報、ご注文いただいた商品の種類や数量、ご請求金額などの情報を利用します。
                            <br />
                            3)
                            お客様に当社が行うキャンペーンや商品・サービスのご案内をするために、ご利用された履歴や、お客様の氏名、住所、e-mailアドレスなどの連絡先情報を利用します。
                            <br />
                            4)
                            当社のサービス改善を行うために、お客様から寄せられたご意見やアンケートの結果、ご利用履歴などを利用します。
                            <br />
                            5)
                            お客様からのご要望、お問い合わせに対する回答をするために、お客様の氏名、住所、e-mailアドレスなどの連絡先情報を利用します。
                            <br />
                            6)
                            個人情報保護法やガイドラインに従って必要な社内体制を整備し、従業員から個人情報の取扱を適正に行う旨の誓約書を取得します。
                            <br />
                            7)
                            個人情報の利用を業務上必要な社員だけに制限し、個人情報が含まれる媒体などの保管・管理などに関する規則を作り、個人情報保護のための予防措置を講じます。
                            <br />
                            8)
                            システムに保存されている個人情報については、業務上必要な社員だけが利用できるようアカウントとパスワードを用意し、アクセス権限管理を実施します。なお、アカウントとパスワードは漏えい、滅失のないよう厳重に管理します。
                            <br />
                            9)
                            インターネットによる個人情報にかかわるデータ伝送時のセキュリティのため、必要なWebページに業界標準の暗号化通信であるSSLを使用します。
                            <br />
                            10)
                            サービスに支障が生じないことを前提として、個人情報の受領時から一定期間経過後、個人情報は随時削除していきます。
                        </P>
                        <H2>2.第三者提供について</H2>
                        <P>
                            ご注文の際には、このプライバシーポリシーを理解のうえ、記載の情報提供がなされることに同意のうえ、ご注文いただくことになります。
                        </P>
                        <H2>3.個人情報の開示について</H2>
                        <P>
                            1)
                            当社は、お客様の同意がない限り、個人情報を第三者に開示することはありません。ただし、以下の事例に該当する場合はその限りではありません。
                            ・法令に基づき裁判所や警察等の公的機関から要請があった場合
                            ・法令に特別の規定がある場合
                            ・お客様や第三者の生命・身体・財産を損なうおそれがあり、本人の同意を得ることができない場合
                            ・法令や当社のご利用規約・注意事項に反する行動から、当社の権利、財産またはサービスを保護または防禦する必要があり、本人の同意を得ることができない場合
                            <br />
                            2)
                            お客様から個人情報の開示要求があった場合は、本人であることが確認できた場合に限り開示します。
                            <br />
                            注：【本人確認の方法】本人であることが証明できるもの（免許証、保険証など）の写しを、当社宛にご郵送してください。
                            内容を確認させていただき、本人であることが明確になり次第、開示させていただきます。
                        </P>
                    </PPWrapper>
                </Grid>
            </Grid>
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: '8px 0 12px',
    display: 'flex',
    justifyContent: 'center',
})
const PPWrapper = styled('div')({
    fontSize: '14px',
    padding: '8px',
})
const H2 = styled('h2')({
    fontSize: 'inherit',
    fontWeight: 'normal',
    margin: '0 0 4px',
})
const P = styled('p')({
    margin: '0 4px 16px',
})
const UL = styled('ul')({
    margin: '-12px 0 16px',
    paddingLeft: '20px',
})

export default PrivacyProlicyPage
